
import {Flex,Box,Text,Button,Heading} from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'

import {useTrail,a} from 'react-spring'
import {graphql} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'
import './trail.css'
import MeinSEO from '../components/SEO'

function Trail({ open, children, ...props }) {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 4000, friction: 600 },
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        height: open ? 100 : 0,
        from: { opacity: 0, x: 0, height: 0 },
        delay:1000,
    })
    return (
        <div className="trails-main" {...props}>
          <MeinSEO title="Manifest" description="Manifest der Theatermacher des Willy Praml Theaters" />
        <Box backgroundColor="black" py="4">
          {trail.map(({ x, height, ...rest }, index) => (
             <a.div
             key={index}
             className="trails-text"
             style={{ ...rest, transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),marginTop:"-6px",color:"white",lineHeigt:1.1 }}>
             <a.div style={{ height }}>{items[index]}</a.div>
           </a.div>
          ))}
        </Box>
      </div>
    )
  }
  
  export default function Theatermanifest({data,location}) {
      
    const [open, set] = useState(false)
    useEffect(()=>{
    set(open => true)
    },[])
    return (
        <Box width="100%" px="2" py="4" overflow="y-scroll" minHeight="960" height={['30vh','100vh']}  location={location}>
          
            <Flex flexDirection="column"
              justifyContent="flex-start"
                alignContent="flex-start"
                  backgroundColor="black"
                 >
                    
          <Box  px={[2,4,7]}>
          <div style={{position:"relative",zIndex:2,top:"2px"}}><Button p="1" my="2" size={["sm","md"]} variant="" onClick={() => set((state) => !state)}>{!open?"OPEN":"CLOSE"}</Button></div>
            <StaticImage src="../images/web/manifest.jpeg" alt="Manifest" />
          <Heading fontSize="4xl" color="red">Das Theatermanifest</Heading>
          
        
      <Trail open={open} onClick={() => set((state) => !state)}>
  
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Räume bespielen:</span><br/> In denen man sich Theater nicht vorstellen kann!</div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die verborgenen Winkel:</span><br/> Ausleuchten!</div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die ewigen Geschichten:</span> <br/>Weitererzählen!</div>
{/* <div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Das Unter-den-Teppich-Gekehrte:</span> <br/>Vom Staube befreien und wieder ANSCHAUEN! </div> */}
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die nicht erinnerten Geschichten:</span><br/> Über die Ufer treten lassen!</div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die Idylle:</span> <br/>Auf den Kopf stellen!</div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Den Fährmann: </span><br/>Die Hauptrolle spielen lassen!</div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die Vögel des Himmels:</span><br/> Choreografieren!</div>

<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die Landschaft:</span><br/>Zur Kunst erklären!</div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die Kunst:</span> <br/>Der Landschaft aussetzen!</div>

<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die Romantik ins 21.Jahrhundert:</span><br/> Katapultieren! </div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Mit der Romantik, nachdem sie ins 21. Jahrhundert katapultiert ist:</span> 
<br/>Die Zukunft denken!</div>
<div style={{padding:"0 0"}}>
  <span style={{color:"red"}}>
    Wagners Ring auf dem Rhein aufführen und nach seinem eigenen Regietraum: 
  </span>
  <span style={{color:'white'}}><br/>Sämtliche Kulissen samt der Partitur in Flammen aufgehen lassen!</span>
</div>
<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die Ufer des Rheins:</span> <br/> Mit den Ufern des Nils, des Mississippi, des Ganges,
des Roten Meeres verwechseln!</div>

<div style={{padding:"0 0",color:'white'}}><span style={{color:"red"}}>Die Verbindung zwischen Theater und Wein:</span> <br/>Zur heiligen Allianz erklären!</div>
<div >
    
     
   <Text sx={{pt:3,pl:3,fontSize:"3rem",marginTop:"-10px",lineHeight:1,color:"white"}}>Mit anderen Worten:<br/> Alle Schleusen öffnen. </Text>
  

</div>   
      </Trail>

    
      
      </Box>    
      </Flex>
   
      </Box>
    )
  }
  
  export const Kquery = graphql`
  query {
    bild1:file(relativePath: { eq: "web/manifest.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth:600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    }`
